const People = {
  data: [
    {
      id: 0,
      name: "MD, MSc., DSc. Svetlana Doubova",
      title: "",
      description:
        "Directora de la Unidad de Investigación Epidemiológica y de Servicios de Salud en el Instituto de Seguridad Social de México.",
    },
    {
      id: 1,
      name: "Dra. Hannah Leslie",
      title: "",
      description:
        "Profesora asistente de la Universidad de California y miembro de la Comisión Lancet de Alta Calidad en los Sistemas de Salud.",
    },
    {
      id: 2,
      name: "Dr. Ezequiel García Elorrio",
      title: "",
      description:
        "Co-Director de la Red QuEST LAC y Director del Instituto de Efectividad Clínica y Sanitaria ",
    },
    {
      id: 3,
      name: "Teresa Tono",
      title: "",
      description:
        "Directora Ejecutiva de la Organización para la Excelencia de la Salud - OES (Centro de Gestión Hospitalaria - CGH).",
      country: "Colombia",
    },
    {
      id: 4,
      name: "Adolfo Rubinstein",
      title:
        "Director del CIIPS - Centro de Implementación e Innovación en Politicas de Salud del IECS",
      description:
        "Director del CIIPS - Centro de Implementación e Innovación en Politicas de Salud del IECS",
      country: "Argentina",
    },
    {
      id: 5,
      name: "María del Carmen Calle",
      title:
        "Secretaria Ejecutiva del Organismo Andino de Salud Convenio Hipólito Unanue",
      description:
        "Secretaria Ejecutiva del Organismo Andino de Salud Convenio Hipólito Unanue",
      country: "Perú",
    },
    {
      id: 6,
      name: "María del Rocío Saénz",
      title: "",
      description:
        "Ex Ministra de Salud – Costa Rica / ex Presidenta de la Caja Costarricense de Seguridad Social.",
      country: "Costa Rica",
    },
    {
      id: 7,
      name: "Enrique Ruelas",
      description:
        "Miembro del Consejo Directivo y Senior Fellow del Instituto para la Mejora en Salud de los Estados Unidos (IHI)",
      country: "Mexico",
    },
    {
      id: 8,
      name: "Dr. Jeremy Veillard",
      description:
        "Especialista Superior en Salud, América Latina y el Caribe del Banco Mundial",
      country: "Colombia",
    },
    {
      id: 9,
      name: "Frederico Guanais",
      description:
        "Jede de la Unidad de Salud en la Organización para la Cooperación y el Desarrollo Económico (OCDE)",
      country: "Brasil",
    },
    {
      id: 10,
      name: "Ana María Malik",
      description:
        "Miembro del grupo GENiE (Global Educators Network for HealthCare Innovation Education)",
      country: "Brasil",
    },
    {
      id: 11,
      name: "Barbara Profeta",
      description:
        "Asesora en Salud para Europa del Este y Asia Central de la Agencia Suiza para la Cooperación y el Desarrollo",
      country: "Suiza",
    },
    {
      id: 12,
      name: "Rosa Suñol",
      description: "Presidenta del Patronato de la Fundación Avedis Donabedian",
      country: "España",
    },
    {
      id: 13,
      name: "Jafet Arrieta",
      description:
        "Directora Senior del Instituto para la Mejora en Salud de los Estados Unidos (IHI)",
      country: "USA",
    },
    {
      id: 14,
      name: "Margaret Kruk",
      description:
        "Directora de la Red QuEST Global y Profesora principal de la Facultad de Salud Pública de la Universidad de Harvard T.H.",
      country: "USA",
    },
    {
      id: 15,
      name: "Sanam Roger Dewan",
      description:
        "Especialista Superior en Salud e Innovación en la Prestación de Servicios en el Banco Mundial",
    },
    {
      id: 16,
      name: "Dr. Jorge Hermilda",
      description:
        "Presidente de la Fundación para la Investigación y Gestión en Servicios de Salud",
    },
    {
      id: 17,
      name: "Dra. Agustina Mazzoni",
      description: "Investigadora Red QuEST LAC IECS",
    },
    {
      id: 18,
      name: "Dra. Patricia Jimenez López",
      description:
        "Representante del Organismo Andino de Salud-Acuerdo Hipolito Unanue ORAS-CONHU",
    },
    {
      id: 19,
      name: "Dra. Catherine Arsenault",
      description:
        "Profesora asistente en el departamento de Salud Global de la Universidad de George Washington",
    },
    {
      id: 20,
      name: "Dr. Jesús Medina Ranilla",
      description:
        "Investigador de la Red QuEST LAC de la Universidad Peruana Cayetano Heredia",
    },
    {
      id: 21,
      name: "Laura Espinoza Pajuelo",
      description:
        "Investigadora Junior de la Universidad Peruana Cayetano Heredia",
    },
    {
      id: 22,
      name: "Dra. Hortensia Reyes Morales",
      description:
        "Directora del Centro de Investigación en Sistemas de Salud del INSP",
    },
    {
      id: 23,
      name: "Dra. Blanca Pelcastre Villafuerte",
      description: "Directora del Área de Equidad en Salud del INSP",
    },
    {
      id: 24,
      name: "Dra. Ileana Heredia Pi",
      description: "Investigadora en Ciencias Médicas del INSP",
    },
    {
      id: 25,
      name: "Dra. Anahí Dreser Mansilla",
      description: "Investigadora en Ciencias Médicas del INSP",
    },
    {
      id: 26,
      name: "Dra. Patricia García Funegra",
      description: "Investigadora principal de la Red QuEST LAC en la Universidad Peruana Cayetano Heredia",
    },
    {
      id: 27,
      name: "PhD. Renzo Calderon-Anyosa",
      description: "Investigador Postdoctoral en la Universidad de Toronto e Investigador de la Universidad Peruana Cayetano Heredia",
    },
    {
      id: 28,
      name: "PhD. Javier Roberti",
      description: "Investigador de la Red QuEST LAC del Instituto de Efectividad Clínica y Sanitaria",
    },
  ],
};

export default People;

/**{   
            "id": 1,
            "title":"curso 1",
            "date":"Fecha 1",
            "time":"Hora 1",
            "image":"vacio",
            "description":"Es un evento tipo curso pero es numero 1",
            "link":"/",
        }
**/
