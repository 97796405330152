import * as React from 'react';
import { GlobalStyle } from '@src/GlobalStyle';
import Navbar from '@components/navbar/Navbar';
import Footer from '@components/Footer/Footer';
import { Helmet } from 'react-helmet';
import TwoStages from '@components/TwoStages/TwoStages';
import Container from '@components/Container/Container';
import styled from 'styled-components';
import RightNavBar from '@components/RightNavBar/RightNavBar';

const Wrapper = styled.div`
width:100%;
height:100%;
display: flex;
flex-direction:column;
padding-left: 1rem;
padding-right: 1rem;
padding-bottom: 2rem;

    @media (min-width: 768px){
        padding-left: 2rem;
        padding-right: 2rem;
    }

`;

const TitleSection = styled.div`
position:relative;
z-index:20;
  h1{
        color: ${props=>props.tc?props.tc:"var(--tema4)"};
        font-size: 2.5rem;
        line-height: 3rem;
        text-align: center;
        margin: 1rem 0;

        

        @media (min-width: 768px){
            text-align: right;
            font-weight: bold;
        }
    }
`

// markup
const TemplatePage1 = ({ content = <></>, title = "RED QuEST LAC", description = "Red QuEST LAC", rightContent = <></>, hero = false, extra = <></>, showtitle= true,tc="" , bg="", adorno=<></> }) => {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Georama:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet"></link>
        <title>{title}</title>
        <meta name="description" content="Objetivos de la Red QuEST en LatinoAmerica y el Caribe" />
        <meta name="keywords" content="QUEST,NETWORK,Latinoamerica,Caribe,PERU,UPCH,ARGENTINA,HARVARD" />
      </Helmet>
      <GlobalStyle />
      <Navbar />
      <Container bg={bg}>
        {adorno}
        <TwoStages

          content={(
            <Wrapper>
              {
              showtitle
              ?(
                <TitleSection tc={tc}>
                <h1>{title}</h1>
              </TitleSection>
              )
              :null
              }
              {content}
            </Wrapper>
          )}
          rightContent={<RightNavBar extra={extra} hero={hero} />}
        />
      </Container>
      <Footer />
    </>

  )
};

export default TemplatePage1
