import img0  from '@images/hexapeople/0.png';
import img1  from '@images/hexapeople/1.png';
import img2  from '@images/hexapeople/2.png';
import img3  from '@images/hexapeople/3.png';
import img4  from '@images/hexapeople/4.png';
import img5  from '@images/hexapeople/5.png';
import img6  from '@images/hexapeople/6.png';
import img7  from '@images/hexapeople/7.png';
import img8  from '@images/hexapeople/8.png';
import img9  from '@images/hexapeople/9.png';
import img10  from '@images/hexapeople/10.png';
import img11  from '@images/hexapeople/11.png';
import img12  from '@images/hexapeople/12.png';
import img13 from '@images/hexapeople/13.png';
import img14  from '@images/hexapeople/14.png';
import img15 from '@images/hexapeople/15.png';


const PeoplePictureLib = [
    img0,img1,img2,img3,img4,img5,img6,img7,img8,img9,img10,img11,img12,img13,img14,img15,
]

export default PeoplePictureLib;